export type ActivityType = {
  id: string;
  displayName: string;
};

enum ActivityTypeIds {
  OSPCAPITAL = "OSPCAPITAL",
  OSPMAINTENANCE = "OSPMAINTENANCE",
  OSPRESTORATION = "OSPRESTORATION",
  OSPWARRANTY = "OSPWARRANTY",
  BASICREPAIR = "BASICREPAIR",
  COMPLEXREPAIR = "COMPLEXREPAIR",
}

const activityTypes: ActivityType[] = [
  { id: ActivityTypeIds.OSPCAPITAL, displayName: "OSP Capital" },
  { id: ActivityTypeIds.OSPMAINTENANCE, displayName: "OSP Maintenance" },
  { id: ActivityTypeIds.OSPRESTORATION, displayName: "OSP Restoration" },
  { id: ActivityTypeIds.OSPWARRANTY, displayName: "OSP Warranty" },
  { id: ActivityTypeIds.BASICREPAIR, displayName: "Basic Repair" },
  { id: ActivityTypeIds.COMPLEXREPAIR, displayName: "Commercial Repair" },
];

export type ActivityTypeGroups = {
  name:
    | "commercial"
    | "residential"
    | "splicing"
    | "quota"
    | "dateAllowed"
    | "dateRequired"
    | "lcpRequired"
    | "maintenance"
    | "warranty"
    | "capital";
  activityTypes: ActivityType[];
};
const activityTypeGroups: ActivityTypeGroups[] = [
  {
    name: "residential",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.BASICREPAIR
    ),
  },
  {
    name: "commercial",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.COMPLEXREPAIR
    ),
  },
  {
    name: "splicing",
    activityTypes: activityTypes.filter(
      (a) =>
        a.id === ActivityTypeIds.OSPCAPITAL ||
        a.id === ActivityTypeIds.OSPMAINTENANCE ||
        a.id === ActivityTypeIds.OSPRESTORATION ||
        a.id === ActivityTypeIds.OSPWARRANTY
    ),
  },
  {
    name: "quota",
    activityTypes: activityTypes.filter(
      (a) =>
        a.id === ActivityTypeIds.BASICREPAIR ||
        a.id === ActivityTypeIds.COMPLEXREPAIR
    ),
  },
  {
    name: "dateAllowed",
    activityTypes: activityTypes.filter(
      (a) =>
        a.id === ActivityTypeIds.OSPRESTORATION ||
        a.id === ActivityTypeIds.BASICREPAIR ||
        a.id === ActivityTypeIds.COMPLEXREPAIR
    ),
  },
  {
    name: "dateRequired",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.OSPRESTORATION
    ),
  },
  {
    name: "lcpRequired",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.OSPCAPITAL
    ),
  },
  {
    name: "maintenance",
    activityTypes: activityTypes.filter(
      (a) =>
        a.id === ActivityTypeIds.OSPRESTORATION ||
        a.id === ActivityTypeIds.OSPMAINTENANCE
    ),
  },
  {
    name: "warranty",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.OSPWARRANTY
    ),
  },
  {
    name: "capital",
    activityTypes: activityTypes.filter(
      (a) => a.id === ActivityTypeIds.OSPCAPITAL
    ),
  },
];
export const splicingActivityTypes =
  activityTypeGroups.find((group) => group.name === "splicing")
    ?.activityTypes ?? [];
export const residentialActivityTypes =
  activityTypeGroups.find((group) => group.name === "residential")
    ?.activityTypes ?? [];
export const businessActivityTypes =
  activityTypeGroups.find((group) => group.name === "commercial")
    ?.activityTypes ?? [];
export const quotaActivityTypes =
  activityTypeGroups.find((group) => group.name === "quota")?.activityTypes ??
  [];
export const dateAllowedTypes =
  activityTypeGroups.find((group) => group.name === "dateAllowed")
    ?.activityTypes ?? [];
export const dateRequiredTypes =
  activityTypeGroups.find((group) => group.name === "dateRequired")
    ?.activityTypes ?? [];
export const lcpRequiredTypes =
  activityTypeGroups.find((group) => group.name === "lcpRequired")
    ?.activityTypes ?? [];
export const maintenanceTypes =
  activityTypeGroups.find((group) => group.name === "maintenance")
    ?.activityTypes ?? [];
export const warrantyTypes =
  activityTypeGroups.find((group) => group.name === "warranty")
    ?.activityTypes ?? [];
export const capitalTypes =
  activityTypeGroups.find((group) => group.name === "capital")?.activityTypes ??
  [];

export function activityIsType(
  activityType: string,
  activityTypes: ActivityType[]
): boolean {
  return activityTypes.some((a) => a.id == activityType);
}

import React, { FC, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { userCan } from "lib/Authorization";

import BackButton from "sections/_global/components/BackButton";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import SearchBox from "sections/_global/components/SearchBox";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import { useUserState } from "sections/_global/contexts/UserContext";

import Services from "../components/ServicesComp/Services";
import styles from "./styles";
import { QUERIES, MUTATIONS } from "../../ScheduleActivityQueries";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getSubscriberData,
  getSubscriberDataVariables,
  getSubscriberData_billingServices_BillingServicesSuccess_services as Service,
  getSubscriberData_metasolvNetworkInventory_MetasolvOntSuccess_inventory as MetasolvInventory,
  getSubscriberData_ofscActivitiesBySubId_OfscActivitiesSuccess_activities as Activity,
  getSubscriberData_ofscActivitiesBySubId_OfscActivitiesSuccess_activities_equipment as ActivityEquipment,
  getSubscriberData_ofscActivitiesBySubId_OfscActivitiesSuccess_activities_services as ActivityService,
  getSubscriberData_ticketLookup_tickets as TicketLookup,
} from "../../__generated__/getSubscriberData";
import { quotaActivityDates_quotaActivityDates_dates as QuotaActivityDate } from "../../__generated__/quotaActivityDates";
import {
  updateOfscActivity,
  updateOfscActivityVariables,
} from "../../__generated__/updateOfscActivity";
import {
  ticket as getTicket,
  ticketVariables as getTicketVariables,
} from "../../__generated__/ticket";
import {
  activityIsType,
  ActivityType,
  businessActivityTypes,
  dateAllowedTypes,
  quotaActivityTypes,
  residentialActivityTypes,
  splicingActivityTypes,
} from "../activityTypes";
import DescriptionTextBox from "../components/DescriptionTextBox/DescriptionTextBox";
import ProjectSearch from "../components/ProjectSearch/ProjectSearch";
import OspFootprintsData, {
  OspTicketData,
} from "../components/OspFootprintsLookup/OspFootprintsLookup";
import QuotaPickerProvider from "../components/QuotaPicker/QuotaPickerProvider";
import {
  convertTicketsForActivity,
  formatDescription,
  getPriorityAddress,
  unescapeString,
} from "./helpers";
import {
  Address,
  DateTime,
  Project,
  Subscriber,
  OspInfo,
  SLA,
  Ticket,
  TicketType,
} from "./types";
import AppAccordion from "../components/AppAccordion/AppAccordion";
import MultiInput from "../components/MultiInput/MultiInput";
import TicketInput from "../components/TicketInput/TicketInput";
import SplicingDataForm from "../components/SplicingDataForm/SplicingDataForm";
import GeneralDataForm from "../components/GeneralDataForm/GeneralDataForm";
import SubscriberInfo from "../components/SubscriberInfo/SubscriberInfo";
import { emailValidation } from "sections/_global/RegexValidation";
import { handleFormValidation } from "./FormValidation";

type Props = {
  classes: {
    title: string;
    menuPaper: string;
  };
};

const ScheduleActivity: FC<Props> = ({ classes }: Props) => {
  const [activityType, setActivityType] = React.useState("");
  const [address, setAddress] = useState<Address>();
  const [dateTime, setDateTime] = React.useState<DateTime>({});
  const [description, setDescription] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [existingActivities, setExistingActivities] = React.useState<
    Activity[]
  >([]);
  const [existingActivity, setExistingActivity] = React.useState<string | null>(
    null
  );
  const [isEmergency, setEmergency] = React.useState<boolean>(false);
  const [metasolvInventory, setMetasolvInventory] = React.useState<
    MetasolvInventory[]
  >([]);
  const [numActivities, setNumActivities] = React.useState<number>(1);
  const [ospInfo, setOspInfo] = React.useState<OspInfo>({});
  const [project, setProject] = React.useState<Project | null>(null);
  const [quota, setQuota] = React.useState<QuotaActivityDate[]>([]);
  const [quotaOffset, setQuotaOffset] = React.useState(0);
  const [resourceId, setResourceId] = React.useState<string | null>(null);
  const [serviceOptions, setServiceOptions] = React.useState<Service[]>([]);
  const [services, setServices] = React.useState<string[]>([]);
  const [sla, setSla] = React.useState<SLA>({ start: null, end: null });
  const [spatialNetInfo, setSpatialNetInfo] = React.useState("");
  const [subscriber, setSubscriber] = useState<Subscriber>(null);
  const [ticket, setTicket] = React.useState<Ticket>();
  const [fpTicket, setFpTicket] = useState<Ticket>();
  const [tickets, setTickets] = React.useState<TicketLookup[]>([]);

  const setLinks = (value: string[]) => {
    setOspInfo({ ...ospInfo, links: value });
  };

  const setEmailsToNotify = (value: string[]) => {
    setOspInfo({ ...ospInfo, emailsToNotify: value });
  };

  const [ospTicketData, setOspTicketData] = useState<OspTicketData | null>(
    null
  );

  const dispatch = useMessageDispatch();
  const user = useUserState();
  const screenIsSmall = false;

  let activityTypes: ActivityType[] =
    subscriber?.marketClass === "B"
      ? businessActivityTypes
      : residentialActivityTypes;

  const splicingTypes = userCan({
    user,
    performAction: "scheduling:osp-activities",
  })
    ? splicingActivityTypes
    : [];
  activityTypes = [...activityTypes, ...splicingTypes];

  const isSplicingType = activityIsType(activityType, splicingActivityTypes);
  const isDateAllowed = activityIsType(activityType, dateAllowedTypes);

  const sendActivity: updateOfscActivityVariables = {
    input: {
      activityType: activityType,
      createUpdateUser: user.username?.toUpperCase() ?? "",
      date: dateTime.date,
      duration: Number(duration),
      equipment: metasolvInventory
        .filter(
          (entry) =>
            entry.serialNumber != null && services.includes(entry.serialNumber)
        )
        .map((entry: MetasolvInventory) => {
          return { serialNumber: entry.serialNumber as string };
        }),
      notes: formatDescription(description),
      ofscActivityId: existingActivity ? Number(existingActivity) : undefined,
      ospInfo: isSplicingType
        ? {
            cablePlaced: ospInfo.isCablePlaced,
            cableSize: ospInfo.cableSize,
            dueDate: ospTicketData?.dueDate,
            emailNotifications: ospInfo.emailsToNotify?.filter(
              (email) => email !== ""
            ),
            hutCabinet: ospInfo.hutCabinet,
            invoiceTaskCode: ospTicketData?.invoiceTaskCode,
            links: ospInfo.links,
            projectCode: project?.clliCode,
            projectName: project?.projectName,
            ring: ospInfo.ring,
            splicePoint: ospInfo.splicePoint,
            splicingRequester: user.username?.toUpperCase() ?? "",
            splicingRequestTeam: ospInfo.splicingRequestTeam,
            ticketType: ospTicketData?.ticketType,
            troubleType: ospTicketData?.troubleType,
            typeOfWork: ospInfo.typeOfWork,
          }
        : undefined,
      quantity: numActivities,
      resourceId: resourceId ?? undefined,
      services: serviceOptions
        .filter((option) => services.includes(String(option.id)))
        .map((service: Service) => {
          return { id: String(service.id), description: service.package?.name };
        }),
      slaWindowStart: sla.start?.toISOString() ?? undefined,
      slaWindowEnd: sla.end?.toISOString() ?? undefined,
      subId: Number(subscriber?.id),
      tickets: convertTicketsForActivity(ticket, fpTicket),
      timeSlot: isSplicingType ? "all-day" : dateTime.timeSlot,
    },
  };

  const [queryTicket, { loading: ticketLoading }] = useLazyQuery<
    getTicket,
    getTicketVariables
  >(QUERIES.getTicket, {
    variables: {
      ticketNumber: ("" + ticket?.ticketNumber) as string,
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.ticket?.ticketDetails?.fields?.[0]) {
        const comments = data.ticket.ticketDetails.fields.find(
          (field: any) => field.key.toLowerCase() === "comments"
        );
        if (comments?.__typename === "KeyValueNullable") {
          setDescription(formatDescription(comments.value ?? ""));
        }
      } else {
        setTicket(undefined);
        dispatch({ type: "CLEAR_MESSAGES" });
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "error",
            description: `No ${ticket?.ticketType.toLowerCase()} ticket/comments found.`,
            isOpen: true,
            id: "ticketLookup",
          },
        });
      }
    },
  });

  const [
    querySubscriberData,
    { data: subscriberData, loading: subscriberLoading },
  ] = useLazyQuery<getSubscriberData, getSubscriberDataVariables>(
    QUERIES.getSubscriberData,
    {
      variables: { subId: subscriber?.id ?? "" },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (
          data &&
          data.billingCustomer?.__typename === "BillingCustomerSuccess"
        ) {
          let name = "";
          let address = null;
          const billingCustomer = data.billingCustomer;
          if (billingCustomer.individual) {
            name = billingCustomer.individual.displayName;
            address = getPriorityAddress(
              billingCustomer.individual.geographicAddress
            );
          } else if (billingCustomer.organization) {
            name = billingCustomer.organization.name ?? "";
            if (billingCustomer.organization.geographicAddress) {
              address = getPriorityAddress(
                billingCustomer.organization.geographicAddress
              );
            }
          }

          setSubscriber({
            id: billingCustomer.customer.customerId.toString(),
            name: name,
            marketClass: billingCustomer.customer.market.marketClass,
          });

          setAddress({
            streetAddress1: address?.streetAddressLine1 ?? "",
            streetAddress2: address?.streetAddressLine2 ?? "",
            city: address?.city ?? "",
            state: address?.stateOrProvince ?? "",
            postalCode: address?.postCode.toString() ?? "",
          });
        } else if (
          data &&
          data.billingCustomer?.__typename === "ErrorMessage"
        ) {
          const errorMessage:
            | string
            | undefined = data.billingCustomer.message?.toLowerCase();
          if (errorMessage?.includes("resource not found")) {
            dispatch({ type: "CLEAR_MESSAGES" });
            dispatch({
              type: "ADD_MESSAGE",
              message: {
                type: "error",
                description: `Invalid Sub ID.`,
                isOpen: true,
                id: "queryBillingCustomer",
              },
            });
          }
        }

        if (
          data?.masterList?.__typename === "MasterListSuccess" &&
          data.masterList.locations?.[0]
        ) {
          setSpatialNetInfo(
            data.masterList.locations[0].equipment?.port?.terminal ?? ""
          );
        }

        if (
          data?.metasolvNetworkInventory?.__typename === "MetasolvOntSuccess" &&
          data.metasolvNetworkInventory.inventory
        ) {
          setMetasolvInventory(
            data.metasolvNetworkInventory.inventory.filter(
              (entry) => entry !== null
            ) as MetasolvInventory[]
          );
        }

        if (
          data?.billingServices?.__typename === "BillingServicesSuccess" &&
          data.billingServices.services
        ) {
          setServiceOptions(
            data.billingServices.services.filter(
              (service) => service?.status.toUpperCase() === "ACTIVE"
            ) as Service[]
          );
        }

        if (
          data?.ofscActivitiesBySubId?.__typename === "OfscActivitiesSuccess" &&
          data.ofscActivitiesBySubId.activities
        ) {
          setExistingActivities(
            data.ofscActivitiesBySubId.activities.filter(
              (activity) => activity !== null
            ) as Activity[]
          );
        }

        if (data?.ticketLookup?.tickets) {
          setTickets(
            data.ticketLookup.tickets.filter(
              (ticket) => ticket !== null
            ) as TicketLookup[]
          );
        }
      },
    }
  );

  const [
    updateOfscActivity,
    { loading: updateOfscActivityLoading },
  ] = useMutation<updateOfscActivity>(MUTATIONS.updateOfscActivity, {
    variables: { sendActivity },
    onCompleted(data) {
      const actionType: string = existingActivity ? "update" : "create";
      dispatch({ type: "CLEAR_MESSAGES" });
      if (data?.updateOfscActivity?.success) {
        clearInputData();
        setQuota([]);
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "success",
            description: `Activity ${actionType}d: ${data?.updateOfscActivity?.ofscIds?.join(
              ", "
            )}`,
            isOpen: true,
            id: "createOfscActivity",
          },
        });
      } else {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "error",
            description: `Failure to ${actionType} activity: ${data?.updateOfscActivity.errorMessage}`,
            isOpen: true,
            id: "createOfscActivity",
          },
        });
      }
    },
  });

  React.useEffect(() => {
    const timeStillAvailable: boolean =
      quota
        .find((entry) => entry.date?.startsWith(dateTime.date ?? ""))
        ?.timeSlots?.find(
          (timeSlot) => timeSlot?.label === dateTime.timeSlot
        ) != null;
    if (
      !timeStillAvailable &&
      activityIsType(activityType, quotaActivityTypes)
    ) {
      setDateTime({});
    }
  }, [quota, activityType, duration, dateTime.date, dateTime.timeSlot]);

  React.useEffect(() => {
    if (ticket?.ticketType == "ZENDESK" && ticket?.ticketNumber) {
      queryTicket({
        variables: {
          ticketNumber: ("" + ticket?.ticketNumber) as string,
        },
      });
    }
  }, [ticket, queryTicket]);

  React.useEffect(() => {
    if (!isSplicingType) {
      setResourceId(null);
      setFpTicket(undefined);
      setLinks([]);
      setProject(null);
      setServices([]);
    }
    if (!isDateAllowed) {
      setDateTime({});
    }
  }, [activityType]);

  React.useEffect(() => {
    const onKeyDown = (event: any) => {
      if (
        event.key === "Enter" &&
        event.target.nodeName === "INPUT" &&
        event.target.form
      ) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  const submitSubscriberInfo = (subId: string): void => {
    if (subId != subscriber?.id) {
      clearAllData();
      querySubscriberData({ variables: { subId } });
    }
  };

  const scheduleActivity = (e: React.FormEvent): void => {
    e.preventDefault();

    const validation = handleFormValidation({
      activityType,
      date: dateTime.formattedDate ?? undefined,
      ospInfo,
    });
    if (validation.isValid) {
      updateOfscActivity({ variables: sendActivity });
    } else {
      dispatch({ type: "CLEAR_MESSAGES" });
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          type: "error",
          description: `Fields with invalid input: ${validation.invalidFields?.join(
            ", "
          )}`,
          isOpen: true,
          id: "search",
        },
      });
    }
  };

  const incrementQuotaOffset = (
    e: React.MouseEvent<{ value: unknown }>
  ): void => {
    const newOffset = Math.min(14, quotaOffset + 7);
    setQuotaOffset(newOffset);
  };

  const decrementQuotaOffset = (
    e: React.MouseEvent<{ value: unknown }>
  ): void => {
    const newOffset = Math.max(0, quotaOffset - 7);
    setQuotaOffset(newOffset);
  };

  const handleDateTimeInput = (e: SelectChangeEvent<string>) => {
    setDateTime({
      date: e.target.value.substring(0, e.target.value.indexOf("!")),
      timeSlot: e.target.value.substring(e.target.value.indexOf("!") + 1),
      dateTime: e.target.value,
    });
  };

  const handleServicesChange = (event: SelectChangeEvent<typeof services>) => {
    const allServices = serviceOptions.map(function (item) {
      return String(item["id"]);
    });

    const allEquipment = metasolvInventory
      .filter((entry) => {
        return entry.serialNumber !== null;
      })
      .map(function (entry) {
        return entry["serialNumber"] as string;
      });

    const options = allServices.concat(allEquipment);

    const values = event.target.value;
    if (values[values.length - 1] === "all") {
      setServices(services.length === options.length ? [] : options);
      return;
    }

    const {
      target: { value },
    } = event;
    setServices(typeof value === "string" ? value.split(",") : value);
  };

  const handleActivityChange = (e: SelectChangeEvent<string>) => {
    clearInputData();
    setExistingActivity(e.target.value);

    const selectedActivity = existingActivities.find(
      (activity) =>
        activity !== null &&
        activity.ofscActivityId?.toString() == e.target.value
    ) as Activity;

    setDuration(String(selectedActivity?.duration ?? ""));
    setActivityType(selectedActivity?.activityType ?? "");
    setDescription(formatDescription(selectedActivity?.notes ?? ""));
    setDateTime({
      date: selectedActivity?.date ?? "",
      timeSlot: selectedActivity?.timeSlot ?? "",
      dateTime: `${selectedActivity?.date ?? ""}!${
        selectedActivity?.timeSlot ?? ""
      }`,
      formattedDate: selectedActivity?.date
        ? new Date(selectedActivity?.date + " 00:00")
        : undefined,
    });
    setFpTicket(undefined);

    if (!selectedActivity) {
      setQuota([]);
    }
    if (selectedActivity?.ticket) {
      setTicket({
        ticketType: (selectedActivity.ticket
          .ticketType as string) as TicketType,
        ticketNumber: (selectedActivity.ticket
          .ticketNumber as unknown) as string,
      });
    }

    const emptyArray: string[] = [];
    const existingServices: string[] = [];

    const services = selectedActivity?.services?.filter(
      (service) =>
        service !== null &&
        serviceOptions.some(
          (existingService) =>
            existingService.id.toString() == String(service.id)
        )
    ) as ActivityService[];

    services?.map(function (service) {
      existingServices.push(String(service["id"] ?? ""));
    });

    const selectedEquipment = selectedActivity?.equipment?.filter(
      (service) => service !== null
    ) as ActivityEquipment[];

    selectedEquipment?.map(function (entry: ActivityEquipment) {
      existingServices.push(entry["serialNumber"]);
    });
    setServices(emptyArray.concat(existingServices));
  };

  function clearAllData() {
    clearInputData();
    clearSubscriberData();
    setQuota([]);
  }

  function clearInputData() {
    setActivityType("");
    setResourceId(null);
    setDateTime({});
    setDescription("");
    setDuration("");
    setEmergency(false);
    setExistingActivity(null);
    setFpTicket(undefined);
    setNumActivities(1);
    setOspInfo({});
    setOspTicketData(null);
    setProject(null);
    setQuotaOffset(0);
    setServices([]);
    setSla({ start: null, end: null });
    setTicket(undefined);
  }

  function clearSubscriberData() {
    setSubscriber(null);
    setAddress(null);
    setTickets([]);
    setExistingActivities([]);
    setMetasolvInventory([]);
    setServiceOptions([]);
    setSpatialNetInfo("");
  }

  return (
    <div>
      <BackButton url="/scheduling-tools" />
      <Typography marginTop="0.5em" variant="h4" className={classes.title}>
        OFSC Schedule Activity
      </Typography>
      <SearchBox
        searchOn="click"
        useFullWidth
        label="Subscriber ID"
        regex="^[0-9\b]+$"
        onSearch={(subId) => {
          submitSubscriberInfo(subId);
        }}
      />
      <LoadingBackdrop
        isOpen={subscriberLoading || updateOfscActivityLoading || ticketLoading}
      />
      {subscriberData?.billingCustomer?.__typename ===
        "BillingCustomerSuccess" &&
        subscriberData.billingCustomer.customer != null && (
          <Card>
            <form onSubmit={scheduleActivity} autoComplete="off">
              <SubscriberInfo
                address={address}
                activityTypes={activityTypes}
                existingActivities={existingActivities}
                existingActivity={existingActivity}
                isSplicingType={isSplicingType}
                spatialNetInfo={spatialNetInfo}
                subscriber={subscriber}
                ticket={ticket}
                tickets={tickets}
                setDescription={setDescription}
                setTicket={setTicket}
                handleActivityChange={handleActivityChange}
              />
              <CardContent>
                <GeneralDataForm
                  activityType={activityType}
                  activityTypes={activityTypes}
                  date={dateTime.formattedDate ?? null}
                  duration={duration}
                  isEmergency={isEmergency}
                  setActivityType={setActivityType}
                  setResourceId={setResourceId}
                  setDate={(newValue) => {
                    newValue
                      ? setDateTime({
                          date: newValue.toISOString().slice(0, 10),
                          formattedDate: newValue,
                        })
                      : setDateTime({});
                  }}
                  setDuration={setDuration}
                  setEmergency={setEmergency}
                  usesQuota={!isSplicingType}
                  user={user}
                />
              </CardContent>
              {isSplicingType && (
                <>
                  <CardContent>
                    <AppAccordion
                      title="Project Search"
                      expanded
                      hideExpandIcon
                    >
                      <ProjectSearch
                        project={project}
                        required={activityIsType(
                          activityType,
                          splicingActivityTypes
                        )}
                        setProject={setProject}
                        activityType={activityType}
                      />
                    </AppAccordion>
                  </CardContent>
                  <CardContent>
                    <SplicingDataForm
                      numActivities={numActivities}
                      ospInfo={ospInfo}
                      sla={sla}
                      setNumActivities={setNumActivities}
                      setOspInfo={setOspInfo}
                      setLcp={() => void 0}
                      setSla={setSla}
                      screenIsSmall={screenIsSmall}
                      showLcp={false}
                    />
                  </CardContent>
                  <CardContent>
                    <AppAccordion title="Ticket Lookup" expanded hideExpandIcon>
                      <>
                        <OspFootprintsData
                          ticketNumber={fpTicket?.ticketNumber ?? ""}
                          setFpTicket={setFpTicket}
                          ospTicketData={ospTicketData}
                          setOspTicketData={setOspTicketData}
                        />
                        <TicketInput
                          ticket={ticket}
                          setTicket={setTicket}
                          ticketType={TicketType.ZENDESK}
                        />
                      </>
                    </AppAccordion>
                  </CardContent>
                  <CardContent>
                    <AppAccordion
                      title="Emails to Notify"
                      expanded
                      hideExpandIcon
                    >
                      <MultiInput
                        input={ospInfo.emailsToNotify ?? []}
                        setInput={setEmailsToNotify}
                        maxFields={100}
                        regex={emailValidation}
                        errorText={"Incorrect email format"}
                        objectName="Email"
                      />
                    </AppAccordion>
                  </CardContent>
                  <CardContent>
                    <AppAccordion title="Links" expanded hideExpandIcon>
                      <MultiInput
                        input={ospInfo.links ?? []}
                        setInput={setLinks}
                        maxFields={3}
                        objectName="Link"
                      />
                    </AppAccordion>
                  </CardContent>
                </>
              )}
              <CardContent>
                <DescriptionTextBox
                  value={description}
                  required
                  handleChange={(e) => {
                    setDescription(unescapeString(e.target.value as string));
                  }}
                ></DescriptionTextBox>
              </CardContent>
              {!isSplicingType && (
                <CardContent>
                  <Services
                    equipment={metasolvInventory}
                    services={serviceOptions}
                    selected={services}
                    handleChange={handleServicesChange}
                  />
                </CardContent>
              )}
              <CardContent>
                <QuotaPickerProvider
                  quota={quota}
                  quotaOffset={quotaOffset}
                  selected={dateTime.dateTime ?? ""}
                  required
                  handleChange={handleDateTimeInput}
                  handleQuotaChange={setQuota}
                  incrementQuotaOffset={incrementQuotaOffset}
                  decrementQuotaOffset={decrementQuotaOffset}
                  subscriberId={subscriber?.id ?? ""}
                  activityType={activityType}
                  duration={Number(duration)}
                  isEmergency={isEmergency}
                />
              </CardContent>
              <CardContent>
                <Button variant="contained" type="submit">
                  Schedule Activity
                </Button>
              </CardContent>
            </form>
          </Card>
        )}
    </div>
  );
};
export default withStyles(styles)(ScheduleActivity);

import React, { FC, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { userCan } from "lib/Authorization";

import BackButton from "sections/_global/components/BackButton";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import { useUserState } from "sections/_global/contexts/UserContext";

import styles from "./styles";
import { MUTATIONS, QUERIES } from "../../ScheduleActivityQueries";

import { useMutation, useLazyQuery } from "@apollo/client";
import {
  updateOfscActivity,
  updateOfscActivityVariables,
} from "../../__generated__/updateOfscActivity";

import {
  activityIsType,
  ActivityType,
  dateAllowedTypes,
  lcpRequiredTypes,
  splicingActivityTypes,
} from "../activityTypes";
import DescriptionTextBox from "../components/DescriptionTextBox/DescriptionTextBox";
import ProjectSearch from "../components/ProjectSearch/ProjectSearch";
import { handleFormValidation } from "./FormValidation";
import {
  Address,
  Coordinates,
  Project,
  OspInfo,
  SLA,
  Ticket,
  TicketType,
} from "./types";
import CoordinatesInput from "../components/CoordinatesInput/CoordinatesInput";
import AddressInput from "../components/AddressInput/AddressInput";
import OspFootprintsData, {
  OspTicketData,
} from "../components/OspFootprintsLookup/OspFootprintsLookup";
import AppAccordion from "../components/AppAccordion/AppAccordion";
import {
  ticket as getTicket,
  ticketVariables as getTicketVariables,
} from "../../__generated__/ticket";

import MultiInput from "../components/MultiInput/MultiInput";
import TicketInput from "../components/TicketInput/TicketInput";
import {
  convertTicketsForActivity,
  formatDescription,
  unescapeString,
} from "./helpers";
import SplicingDataForm from "../components/SplicingDataForm/SplicingDataForm";
import GeneralDataForm from "../components/GeneralDataForm/GeneralDataForm";
import ActivityCopy from "../components/ActivityCopy/ActivityCopy";
import { emailValidation } from "sections/_global/RegexValidation";

type Props = {
  classes: {
    title: string;
    menuPaper: string;
  };
};

const ScheduleActivityForLocation: FC<Props> = ({ classes }: Props) => {
  const [activityType, setActivityType] = React.useState("");
  const [address, setAddress] = useState<Address>({});
  const [coordinates, setCoordinates] = useState<Coordinates>({});
  const [date, setDate] = React.useState<Date | null>(null);
  const [description, setDescription] = React.useState("");
  const [duration, setDuration] = React.useState<string>("");
  const [isEmergency, setEmergency] = React.useState<boolean>(false);
  const [numActivities, setNumActivities] = React.useState<number>(1);
  const [ospInfo, setOspInfo] = React.useState<OspInfo>({});
  const [ospTicketData, setOspTicketData] = useState<OspTicketData | null>(
    null
  );
  const [project, setProject] = React.useState<Project | null>(null);
  const [resourceId, setResourceId] = React.useState<string | null>(null);
  const [sla, setSla] = React.useState<SLA>({ start: null, end: null });
  const [lcp, setLcp] = React.useState("");
  const [fpTicket, setFpTicket] = React.useState<Ticket>();
  const [zdTicket, setZdTicket] = React.useState<Ticket>();

  const [isEnableCopy, setEnableCopy] = React.useState<boolean>(false);
  const [activityToCopy, setActivityToCopy] = React.useState("");

  const setLinks = (value: string[]) => {
    setOspInfo({ ...ospInfo, links: value });
  };

  const setEmailsToNotify = (value: string[]) => {
    setOspInfo({ ...ospInfo, emailsToNotify: value });
  };

  const dispatch = useMessageDispatch();
  const user = useUserState();
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const splicingTypes = userCan({
    user,
    performAction: "scheduling:osp-activities",
  })
    ? splicingActivityTypes
    : [];
  const activityTypes: ActivityType[] = [...splicingTypes];

  const isSplicingType = activityIsType(activityType, splicingActivityTypes);
  const isDateAllowed = activityIsType(activityType, dateAllowedTypes);

  const sendActivity: updateOfscActivityVariables = {
    input: {
      activityType: activityType,
      address: {
        address1: address?.streetAddress1 ?? "",
        address2: address?.streetAddress2 ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        postalCode: address?.postalCode ?? "",
      },
      coordinates:
        coordinates.latitude && coordinates.longitude
          ? {
              latitude: Number(coordinates.latitude),
              longitude: Number(coordinates.longitude),
            }
          : undefined,
      createUpdateUser: user.username?.toUpperCase() ?? "",
      date: date?.toISOString().slice(0, 10) ?? undefined,
      duration: Number(duration),
      notes: formatDescription(description),
      ospInfo: isSplicingType
        ? {
            cablePlaced: ospInfo.isCablePlaced,
            cableSize: ospInfo.cableSize,
            dueDate: ospTicketData?.dueDate,
            emailNotifications: ospInfo.emailsToNotify?.filter(
              (email) => email !== ""
            ),
            hutCabinet: ospInfo.hutCabinet,
            invoiceTaskCode: ospTicketData?.invoiceTaskCode,
            links: ospInfo.links,
            projectCode: project?.clliCode,
            projectName: project?.projectName,
            ring: ospInfo.ring,
            splicePoint: ospInfo.splicePoint,
            splicingRequester: user.username?.toUpperCase() ?? "",
            splicingRequestTeam: ospInfo.splicingRequestTeam,
            ticketType: ospTicketData?.ticketType,
            troubleType: ospTicketData?.troubleType,
            typeOfWork: ospInfo.typeOfWork,
          }
        : undefined,
      quantity: numActivities,
      resourceId: resourceId,
      slaWindowStart: sla.start?.toISOString() ?? undefined,
      slaWindowEnd: sla.end?.toISOString() ?? undefined,
      terminal: lcp,
      tickets: convertTicketsForActivity(zdTicket, fpTicket),
      timeSlot: "all-day",
    },
  };

  const [
    updateOfscActivity,
    { loading: updateOfscActivityLoading },
  ] = useMutation<updateOfscActivity>(MUTATIONS.updateOfscActivity, {
    variables: { sendActivity },
    onCompleted(data) {
      const actionType = "create";
      dispatch({ type: "CLEAR_MESSAGES" });
      if (data?.updateOfscActivity?.success) {
        clearInputData();
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "success",
            description: `Activity ${actionType}d: ${data?.updateOfscActivity?.ofscIds?.join(
              ", "
            )}`,
            isOpen: true,
            id: "createOfscActivity",
          },
        });
      } else {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "error",
            description: `Failure to ${actionType} activity: ${data?.updateOfscActivity.errorMessage}`,
            isOpen: true,
            id: "createOfscActivity",
          },
        });
      }
    },
  });

  const scheduleActivity = (e: React.FormEvent): void => {
    e.preventDefault();

    const validation = handleFormValidation({
      activityType,
      date: date ?? undefined,
      coordinates,
      address,
      ospInfo,
    });
    if (validation.isValid) {
      updateOfscActivity({ variables: sendActivity });
    } else {
      dispatch({ type: "CLEAR_MESSAGES" });
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          type: "error",
          description: `Fields with invalid input: ${validation.invalidFields?.join(
            ", "
          )}`,
          isOpen: true,
          id: "search",
        },
      });
    }
  };

  const [queryTicket, { loading: ticketLoading }] = useLazyQuery<
    getTicket,
    getTicketVariables
  >(QUERIES.getTicket, {
    variables: {
      ticketNumber: ("" + zdTicket?.ticketNumber) as string,
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.ticket?.ticketDetails?.fields?.[0]) {
        const comments = data.ticket.ticketDetails.fields.find(
          (field: any) => field.key.toLowerCase() === "comments"
        );
        if (comments?.__typename === "KeyValueNullable") {
          setDescription(formatDescription(comments.value ?? ""));
        }
      } else {
        setZdTicket(undefined);
        dispatch({ type: "CLEAR_MESSAGES" });
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "error",
            description: `No ${zdTicket?.ticketType.toLowerCase()} ticket/comments found.`,
            isOpen: true,
            id: "ticketLookup",
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (zdTicket?.ticketType == "ZENDESK" && zdTicket?.ticketNumber) {
      queryTicket({
        variables: {
          ticketNumber: ("" + zdTicket?.ticketNumber) as string,
        },
      });
    }
  }, [zdTicket, queryTicket]);

  React.useEffect(() => {
    const onKeyDown = (event: any) => {
      if (
        event.key === "Enter" &&
        event.target.nodeName === "INPUT" &&
        event.target.form
      ) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  React.useEffect(() => {
    if (!isSplicingType) {
      setResourceId(null);
      setFpTicket(undefined);
      setLinks([]);
      setProject(null);
    }
    if (!isDateAllowed) {
      setDate(null);
    }
  }, [activityType]);

  function clearInputData() {
    setActivityToCopy("");
    setActivityType("");
    setAddress({});
    setResourceId(null);
    setCoordinates({});
    setDate(null);
    setDescription("");
    setDuration("");
    setEnableCopy(false);
    setFpTicket(undefined);
    setLcp("");
    setNumActivities(1);
    setOspInfo({});
    setOspTicketData(null);
    setProject(null);
    setSla({ start: null, end: null });
    setZdTicket(undefined);
  }

  return (
    <div>
      <LoadingBackdrop isOpen={updateOfscActivityLoading || ticketLoading} />
      <BackButton url="/scheduling-tools" />
      <Typography marginTop="0.5em" variant="h4" className={classes.title}>
        OFSC Schedule Activity By Location
      </Typography>
      <Card>
        <form onSubmit={scheduleActivity} autoComplete="off">
          <CardContent>
            <AppAccordion title="General" defaultExpanded>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
                marginBottom="1em"
              >
                <Grid item md={3} xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEnableCopy}
                        onChange={(e) => setEnableCopy(e.target.checked)}
                        name="copy"
                      />
                    }
                    label="Copy From Existing Activity"
                  />
                </Grid>
                {isEnableCopy && (
                  <Grid item md={3} xs={6}>
                    <ActivityCopy
                      activityId={activityToCopy}
                      setActivityId={setActivityToCopy}
                      setActivityType={(type) =>
                        setActivityType(
                          activityTypes.find((t) => t.id === type)?.id ?? ""
                        )
                      }
                      setAddress={setAddress}
                      setResourceId={setResourceId}
                      setCoordinates={setCoordinates}
                      setDate={setDate}
                      setDescription={setDescription}
                      setDuration={setDuration}
                      setFpTicket={setFpTicket}
                      setOspInfo={setOspInfo}
                      setProject={setProject}
                      setSla={setSla}
                      setLcp={setLcp}
                      setZdTicket={setZdTicket}
                    />
                  </Grid>
                )}
              </Grid>
              <GeneralDataForm
                activityType={activityType}
                activityTypes={activityTypes}
                date={date}
                duration={duration}
                isEmergency={isEmergency}
                setActivityType={setActivityType}
                setDate={setDate}
                setDuration={setDuration}
                setEmergency={setEmergency}
                setResourceId={setResourceId}
                usesQuota={false}
                user={user}
              />
            </AppAccordion>
            <AppAccordion title="Location" defaultExpanded>
              <Typography marginBottom="0.5em">Coordinates</Typography>
              <CoordinatesInput
                coordinates={coordinates}
                required={
                  !(
                    address?.city &&
                    address?.state &&
                    address?.postalCode &&
                    address?.streetAddress1
                  )
                }
                onChange={setCoordinates}
              />
              <Typography marginTop="0.5em" marginBottom="0.5em">
                Address
              </Typography>
              <AddressInput
                address={address}
                onChange={setAddress}
                required={!(coordinates.latitude && coordinates.longitude)}
              />
            </AppAccordion>
            {isSplicingType && (
              <>
                <AppAccordion title="Project Search" expanded hideExpandIcon>
                  <ProjectSearch
                    project={project}
                    required={activityIsType(
                      activityType,
                      splicingActivityTypes
                    )}
                    setProject={setProject}
                    activityType={activityType}
                  />
                </AppAccordion>
                <SplicingDataForm
                  numActivities={numActivities}
                  ospInfo={ospInfo}
                  sla={sla}
                  lcp={lcp}
                  setNumActivities={setNumActivities}
                  setOspInfo={setOspInfo}
                  setSla={setSla}
                  setLcp={setLcp}
                  screenIsSmall={screenIsSmall}
                  requireLCP={activityIsType(activityType, lcpRequiredTypes)}
                  showLcp={true}
                />
                <AppAccordion title="Ticket Lookup" expanded hideExpandIcon>
                  <>
                    <OspFootprintsData
                      ticketNumber={fpTicket?.ticketNumber ?? ""}
                      setFpTicket={setFpTicket}
                      ospTicketData={ospTicketData}
                      setOspTicketData={setOspTicketData}
                    />
                    <TicketInput
                      ticket={zdTicket ?? undefined}
                      setTicket={setZdTicket}
                      ticketType={TicketType.ZENDESK}
                    />
                  </>
                </AppAccordion>
                <AppAccordion title="Emails to Notify" expanded hideExpandIcon>
                  <MultiInput
                    input={ospInfo.emailsToNotify ?? []}
                    setInput={setEmailsToNotify}
                    maxFields={100}
                    regex={emailValidation}
                    errorText={"Incorrect email format"}
                    objectName={"Email"}
                  />
                </AppAccordion>
                <AppAccordion title="Links" expanded hideExpandIcon>
                  <MultiInput
                    input={ospInfo.links ?? []}
                    setInput={setLinks}
                    maxFields={3}
                    objectName={"Link"}
                  />
                </AppAccordion>
              </>
            )}
          </CardContent>
          <CardContent>
            <DescriptionTextBox
              value={description}
              required
              handleChange={(e) => {
                setDescription(unescapeString(e.target.value as string));
              }}
            ></DescriptionTextBox>
          </CardContent>
          <CardContent>
            <Button variant="contained" type="submit">
              Schedule Activity
            </Button>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};
export default withStyles(styles)(ScheduleActivityForLocation);
